<template lang="pug">
  b-card(v-if="documentSailor" header-tag="header")
    template(#header)
      div(class="flex-row-sb")
        div(class="text-uppercase text-left")
          | {{ $t(`${Object.keys(documentSailor.behavior)[0]}-${type}`, { institution: getDirectoryObject({value: 'institution', id:documentSailor.educational_institution})[labelName]}) }}
          span(v-if="checkAccess('backOffice')")
            | (ID: {{ documentSailor.id }})

        div(class="documentIconControl")
          unicon(
            @click="viewDetailedComponent(documentSailor, 'viewInfoBlock')"
            fill="#42627e"
            height="25px"
            width="25px"
            class="cursor mr-4"
            name="info-circle")
            //- v-if="checkAccess(type, 'edit')"
          unicon(
            @click="viewDetailedComponent(documentSailor, 'viewEditBlock')"
            name="pen"
            fill="#42627e"
            height="25px"
            width="25px"
            class="cursor mr-4")

          //- unicon(
          //-   v-if="checkAccess(type, 'delete')"
          //-   @click="deleteDocument"
          //-   name="trash-alt"
          //-   fill="#42627e"
          //-   height="25px"
          //-   width="25px"
          //-   class="cursor mr-4")

          unicon(
            @click="back('vnz-backoffice')"
            name="multiply"
            fill="#42627e"
            height="25px"
            width="25px"
            class="close")

    Info(
      v-if="documentSailor.behavior.viewInfoBlock"
      :documentSailor="documentSailor")

    Edit(
      v-else-if="row.item.behavior.viewEditBlock"
      :documentSailor="documentSailor")
</template>
<script>
import { back, deleteConfirmation, viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'
import Info from './Info'
export default {
  name: 'documentVnzBackoffice',
  data () {
    return {
      back,
      deleteConfirmation,
      viewDetailedComponent,
      checkAccess,
      documentSailor: null,
      type: 'backOfficeVnz'

    }
  },
  pros: {
    row: Object
  },
  components: {
    Info
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    documentID () {
      return this.$route.params.vnz_id
    }
  },
  methods: {
    ...mapActions(['getInstitution']),
    async getData () {
      this.documentSailor = await this.getInstitution(this.documentID)
    }
  },
  mounted () {
    this.getData()
  }

}
</script>
