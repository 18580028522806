<template lang="pug">
   div.seafarerInfoList.text-left
    div.w-50
      b {{ $t('nameInstitution') }}:
      | {{ getDirectoryObject({value: 'institution', id: documentSailor.educational_institution})[labelName] }}
    div.w-50
      b {{ $t('qualification') }}:
      | {{ getDirectoryObject({value: 'qualificationLevels', id: documentSailor.level_qualification})[labelName] }}
    div.w-50
      b {{ $t('dateEffective') }}:
      | {{ getDateFormat(documentSailor.date_start) }}
    div.w-50
      b {{ $t('dateTermination') }}:
      | {{ getDateFormat(documentSailor.date_end) }}
    div.w-100
      b {{ $t('price')}}:
      | {{ documentSailor.price }}
</template>

<script>
import { getDateFormat, getPaymentStatus } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'list-vnz-backoffice-info',
  props: {
    documentSailor: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat,
      getPaymentStatus
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject'])
  }
}
</script>
